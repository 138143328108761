.admin-signup-holder {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  .login-holder {
    border-radius: 0px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 20px 40px;
    width: 380px;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
    .login-box {
      text-align: center;
      .list-container {
        position: absolute;
        z-index: 3;
        width: 100%;
        background: #fff;
        box-shadow: 0px 5px 7px 2px #0000001f;
        text-align: left;
        max-height: 170px;
        overflow: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #f5f5fa;
        }
        &::-webkit-scrollbar {
          width: 6px;
          background-color: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          max-height: 10px;
          background-color: #e9e8e8;
        }
        i {
          margin-right: 7px;
        }
        ul {
          margin-left: -40px;
          li {
            display: block;
            cursor: pointer;
            width: 100%;
            padding: 10px 5px;
            border-bottom: 1px solid #00000026;
            margin: 0;
            padding-left: 13px;
            font-size: 14px;
            font-family: "Roboto";
            &:hover {
              background-color: #ddd;
            }
          }
        }
      }
      .small {
        color: #212529;
        font-size: 12px;
        font-family: Roboto;
        text-align: right !important;
      }
      .form-group {
        position: relative;
        .icon-selected {
          position: absolute;
          right: 5px;
          top: 5px;
        }
        .err {
          margin-bottom: 8px;
          position: absolute;
          top: 38px;
          display: block;
          color: red !important;
          font-size: 13px;
        }
        button {
          border-radius: 0px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #535259;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
        }
        input {
          height: 40px;
          border-radius: 0px;
          border: 1px solid #b2b0bf;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #535259;

          &:focus {
            border: 1px solid #8b7df2;
            box-shadow: none;
          }
        }
      }
      .has-search {
        position: relative;
        .form-control-feedback {
          position: absolute;
          z-index: 2;
          display: block;
          width: 2.375rem;
          height: 2.375rem;
          line-height: 2.375rem;
          text-align: center;
          pointer-events: none;
          color: #aaa;
          margin-top: 5px;
          width: 24px;
          margin-left: 9px;
          img {
            width: 20px;
            height: 20px;
            vertical-align: unset !important;
          }
        }
        .form-control-feedback1 {
          position: absolute;
          right: 8px;
          z-index: 2;
          display: block;
          pointer-events: none;
          top: 10px;
          img {
            width: 20px;
            height: 20px;
            vertical-align: unset !important;
          }
        }
        .form-control {
          padding-left: 2.3rem;
        }
      }
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #242326;
        margin-top: 5px;
      }
      #subdomain {
        border-right: none;
      }
      #subdomaintwo {
        border-left: none;
        padding-left: 0px;
        background: #fff;
        width: 60%;
        color: #82818c;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  // &::after {
  //   content: " ";
  //   position: absolute;
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   border-radius: 24px;
  //   top: 0;
  //   left: 0;
  //   z-index: 0;
  //   background: #fafafc;
  //   transform-origin: top;
  //   -ms-transform: skew(-5deg, 0deg);
  //   -webkit-transform: skew(-5deg, 0deg);
  //   transform: skew(-5deg, 0deg);
  // }
}
.button-color {
  background-color: #8b7df2 !important;
  color: #fff !important;
}
