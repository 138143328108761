$width: 100;
$white-background: #fff;

.ball-loader {
    width: $width + 0px;
    height: ($width / 3) - 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.ball-loader-ball {
    will-change: transform;
    height: ($width / 3) - 10px;
    width: ($width / 3) - 10px;
    border-radius: 50%;
    background-color: $white-background;
    position: absolute;
    animation: grow 1s ease-in-out infinite alternate;

    &.ball1 {
        left: 0;
        transform-origin: 100% 50%;
    }
    &.ball2 {
        left: 50%;
        transform: translateX(-50%) scale(1);
        animation-delay: 0.33s;
    }
    &.ball3 {
        right: 0;
        animation-delay: 0.66s;
    }
}

@keyframes grow {
    to {
        transform: translateX(-50%) scale(0);
    }
}

.la-ball-clip-rotate,
.la-ball-clip-rotate > div {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.la-ball-clip-rotate {
    display: block;
    font-size: 0;
    color: #fff;
}
.la-ball-clip-rotate.la-dark {
    color: #333;
}
.la-ball-clip-rotate > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}
.la-ball-clip-rotate {
    width: 25px;
    height: 25px;
}
.la-ball-clip-rotate > div {
    width: 25px;
    height: 25px;
    background: transparent;
    border-width: 2px;
    border-bottom-color: transparent;
    border-radius: 100%;
    -webkit-animation: ball-clip-rotate 0.75s linear infinite;
    -moz-animation: ball-clip-rotate 0.75s linear infinite;
    -o-animation: ball-clip-rotate 0.75s linear infinite;
    animation: ball-clip-rotate 0.75s linear infinite;
}
.la-ball-clip-rotate.la-sm {
    width: 16px;
    height: 16px;
}
.la-ball-clip-rotate.la-sm > div {
    width: 16px;
    height: 16px;
    border-width: 1px;
}


/*
 * Animation
 */
@-webkit-keyframes ball-clip-rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes ball-clip-rotate {
    0% {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -moz-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes ball-clip-rotate {
    0% {
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes ball-clip-rotate {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
